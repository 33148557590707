export const texts = {
  calendarsPage: {
    pageHeading: 'Мои карты',
    availableKeys: 'Доступных лопат',
    keysDescription:
      'Используй лопаты, чтобы выкопать сундуки с сокровищами и получить золотой билет для розыгрыша!',
    listItemTitle: 'Карта',
    errorMsgTop: 'Упс! Произошла ошибка',
    errorMsgBottom: 'загрузки твоих карт',
  },
  homePage: {
    headingDesktop: ['Открывай сундуки ', 'на острове ', 'и собирай подарки!'],
    headingMobile: ['Открывай ', 'сундуки на острове ', 'и собирай подарки!'],
    headingMobileShorter: [''],
    headingAlt: 'Открывай сундуки на острове и собирай подарки',
    toCalendars: 'К картам',
    promoactionFinished: ['Акция', 'завершена!'],
    seeYouInNewGames: 'До встречи в новых играх!',
  },
  popups: {
    keysReminder: {
      heading: ['Йо-хо-хо, юнга!', 'Не забудь про лопаты!'],
      contentSectionTop: 'Они помогут тебе откопать сокровища на островах!',
      contentSectionBottom:
        'Тебя ждёт гарантированный приятный сюрприз в каждом сундуке!',
      keys: 'Лопаты',
      button: 'Продолжить',
    },
    ticketGift: {
      heading: ['Тысяча чертей, юнга!', 'Тебе несметно повезло!'],
      subheading: ['Поздравляем! Ты получил ', 'золотой билет!'],
      button: 'Продолжить',
    },
    fortuneGift: {
      heading: ['Йо-хо-хо, юнга!', 'А вот и твой подарок!'],
      subheading: ['Поздравляем! Ты получил'],
      button: 'Закрыть',
    },
    couponGift: {
      heading: ['Йо-хо-хо, юнга!', 'А вот и твой подарок!'],
      subheading: ['Поздравляем! Ты получил'],
    },
    promocodeGift: {
      heading: ['Йо-хо-хо, юнга!', 'А вот и твой подарок!'],
      subheading: ['Поздравляем! Ты получил'],
    },
    calendarError: {
      heading: 'Карамба!',
      content: 'Произошла ошибка загрузки карты',
    },
    giftError: {
      heading: 'Карамба!',
      content: 'Произошла ошибка при откапывании сундука',
    },
    rules: {
      heading: ['Йо-хо-хо, и бутылка рома!', 'Добро пожаловать на борт!'],
      content: 'Прежде чем отправиться в путь, ознакомься с ',
    },
    notHaveKeys: {
      heading: 'У тебя нет лопат :(',
      content:
        'Покупай на сумму от 800₽ или товары-спонсоры в магазинах SPAR, Победа и Семья, чтобы получить их',
    },
    allDaysOpened: {
      heading: 'Все сокровища выкопаны!',
      content:
        'Переходи к следующей карте, чтобы получить ещё больше подарков!',
      button: 'Перейти',
    },
    gameStartingDate: {
      heading: ['Добро пожаловать!'],
      subheading: ['Готовим подарки!', 'Игра начнётся 14 октября'],
      button: 'Продолжить',
    },
    actionEnd: {
      heading:
        'Акция завершена. С её результатами вы можете ознакомиться в социальных сетях SPAR Калининград',
      content: '',
    },
  },
  keyDescription: {
    heading: 'Это лопата',
    description:
      'Чтобы откопать сундук, потяни лопату к острову с закопанным сундуком',
  },
  keysBox: {
    keys: 'Лопаты',
  },
  keysCarousel: {
    emptyMessage: 'Совершай покупки и получай лопаты',
  },
}

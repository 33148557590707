import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { PATHS } from 'routing/paths'
import { Box, Button, Flex, TextTitle } from 'ui/atoms'
import { Notice } from 'ui/organisms'
import { LayoutMobile } from 'ui/layout'
import { config } from 'config'
import { TextTitleHome } from './TextTitleHome'
import { toCalendarsButtonProps } from './toCalendarsButtonProps'
import { myTicketsButtonProps } from './myTicketsButtonProps'
import { layoutMobileProps } from './layoutMobileProps'
import { useLogoBackgroundSize } from './useLogoBackgroundSize'
import { useHeadingTextContainerMarginBottom } from './useHeadingTextContainerMarginBottom'
import { useHeadingText } from './useHeadingText'
import { useLayoutMobileBodyProps } from './useLayoutMobileBodyProps'
import { useButtonsPy } from './useButtonsPy'

const TextTitleSmallerHome = styled(TextTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.textTertiaryColor}`};
  text-transform: none;
`

export const HomeMobile = () => {
  const logoBackgroundSize = useLogoBackgroundSize()
  const headingTextContainerMarginBottom = useHeadingTextContainerMarginBottom()
  const headingText = useHeadingText()
  const layoutMobileBodyProps = useLayoutMobileBodyProps()
  const buttonsPy = useButtonsPy()

  return (
    <LayoutMobile
      {...config.backgrounds.HOME_MOBILE_BACKGROUND}
      {...layoutMobileProps}
      {...logoBackgroundSize}
      {...layoutMobileBodyProps}
    >
      <LayoutMobile.Content>
        <Flex
          display="flex"
          width="100%"
          flexDirection="column"
          alignItems="center"
        >
          <Notice mb={{ _: '50px', md: '28px' }} />

          {/* <Box
            alt={config.texts.homePage.headingAlt}
            maxWidth={{ _: '100%', md: '486px' }}
            mb={{ _: '50px', md: '58px' }}
            style={{ textAlign: 'center' }}
            {...headingTextContainerMarginBottom}
          >
            {headingText.map((textLine, index) => {
              return (
                <TextTitleHome fontSize="28px" key={index}>
                  {textLine}
                </TextTitleHome>
              )
            })}
          </Box> */}

          <Box
            maxWidth="260px"
            mt="36px"
            mb="16px"
            style={{ textAlign: 'center' }}
          >
            {config.texts.homePage.promoactionFinished.map(
              (textLine, index) => {
                return (
                  <TextTitleHome fontSize="32px" key={index}>
                    {textLine}
                  </TextTitleHome>
                )
              }
            )}
          </Box>

          <Box
            maxWidth={{ _: '100%', md: '486px' }}
            mb="33px"
            style={{ textAlign: 'center' }}
          >
            <TextTitleSmallerHome fontSize="24px">
              {config.texts.homePage.seeYouInNewGames}
            </TextTitleSmallerHome>
          </Box>
          {/* 
          <Box maxWidth={{ md: '486px' }} w="100%">
            <Link to={PATHS.CALENDARS}>
              <Button {...toCalendarsButtonProps} {...buttonsPy}>
                {config.texts.homePage.toCalendars}
              </Button>
            </Link>

            <Link to={PATHS.TICKETS}>
              <Button {...myTicketsButtonProps} {...buttonsPy}>
                Мои билеты
              </Button>
            </Link>
          </Box> */}
        </Flex>
      </LayoutMobile.Content>
    </LayoutMobile>
  )
}

export const texts = {
  calendarsPage: {
    pageHeading: 'Мои карты',
    availableKeys: 'Доступных монет',
    keysDescription:
      'Используй монеты, чтобы открывать подарки и получать золотой билет для розыгрыша!',
    listItemTitle: 'Карта',
    errorMsgTop: 'Упс! Произошла ошибка',
    errorMsgBottom: 'загрузки твоих карт',
  },
  homePage: {
    headingDesktop: ['Осень подарков ', 'от KD Pay и МТС'],
    headingMobile: ['Осень подарков ', 'от KD Pay и МТС'],
    headingMobileShorter: [''],
    headingAlt: 'Открывай сундуки на острове и собирай подарки',
    toCalendars: 'К скретч-картам',
    promoactionFinished: ['Акция', 'завершена!'],
    seeYouInNewGames: 'До встречи в новых играх!',
  },
  popups: {
    keysReminder: {
      heading: ['Не забудь про монеты!'],
      contentSectionTop: 'Они помогут тебе открыть приз!',
      contentSectionBottom: '',
      keys: 'Монеты',
      button: 'Продолжить',
    },
    ticketGift: {
      heading: ['Поздравляем! Ты ', 'получил золотой билет!'],
      subheading: [],
      button: 'Продолжить',
    },
    fortuneGift: {
      heading: ['Поздравляем! Ты получил'],
      subheading: [],
    },
    couponGift: {
      heading: ['Поздравляем! Ты получил'],
      subheading: [],
    },
    promocodeGift: {
      heading: ['Поздравляем! Ты получил'],
      subheading: [],
    },
    calendarError: {
      heading: 'Упс!',
      content: 'Произошла ошибка загрузки карты!',
    },
    giftError: {
      heading: 'Упс!',
      content: 'Произошла ошибка при открытии подарка!',
    },
    rules: {
      heading: ['Добро пожаловать!'],
      content: 'Прежде чем начать игру, ознакомься с ',
    },
    notHaveKeys: {
      heading: 'У тебя нет монет :(',
      content:
        'Оплачивай покупки с KD Pay на сумму от 500₽ в магазинах SPAR, Победа и Семья, чтобы получить больше монет!',
    },
    allDaysOpened: {
      heading: 'Все подарки открыты!',
      content:
        'Переходи к следующей карте, чтобы получить ещё больше ценных призов!',
      button: 'Продолжить',
    },
    gameStartingDate: {
      heading: ['Добро пожаловать!'],
      subheading: ['Готовим подарки!', 'Игра начнётся 14 октября'],
      button: 'Продолжить',
    },
    actionEnd: {
      heading:
        'Акция завершена. С её результатами вы можете ознакомиться в социальных сетях SPAR Калининград',
      content: '',
    },
  },
  keyDescription: {
    heading: 'Это монета',
    description: 'Чтобы открыть подарок, сотри защитный слой монетой',
  },
  keysBox: {
    keys: 'Монетки',
  },
  keysCarousel: {
    emptyMessage: 'Совершай покупки с KD Pay и получай монеты',
  },
}

export const texts = {
  calendarsPage: {
    pageHeading: 'Мои ёлочки',
    availableKeys: 'Доступных молотков',
    keysDescription:
      'Разбивай молотками шары на  ёлочке, чтобы получить золотые билеты для розыгрыша',
    listItemTitle: 'Ёлочка',
    errorMsgTop: 'Упс! Произошла ошибка',
    errorMsgBottom: 'загрузки твоих ёлочек',
  },
  homePage: {
    headingDesktop: ['Разбивай ', 'игрушки на ёлке ', 'и собирай подарки'],
    headingMobile: ['Разбивай ', 'игрушки на ёлке ', 'и собирай подарки'],
    headingMobileShorter: ['Разбивай игрушки ', 'и собирай подарки'],
    headingAlt: 'Разбивай игрушки на ёлке и собирай подарки',
    toCalendars: 'К ёлочкам',
    promoactionFinished: ['Акция завершена!'],
    seeYouInNewGames: 'До встречи в новых играх!',
  },
  popups: {
    keysReminder: {
      heading: ['Не забудь про молотки!'],
      contentSectionTop: 'Разбивай молотками игрушки и забирай подарки',
      contentSectionBottom:
        'Тебя ждёт гарантированный приятный сюрприз за каждой игрушкой!',
      keys: 'Молотки',
      button: 'За подарками!',
    },
    ticketGift: {
      heading: ['Поздравляем! Ты ', 'получил золотой билет!'],
      subheading: [],
      button: 'Продолжить',
    },
    fortuneGift: {
      heading: ['А вот и твой подарок!'],
      subheading: ['Поздравляем! Ты получил'],
    },
    couponGift: {
      heading: ['А вот и твой подарок!'],
      subheading: ['Поздравляем! Ты получил'],
    },
    promocodeGift: {
      heading: ['А вот и твой подарок!'],
      subheading: ['Поздравляем! Ты получил'],
    },
    calendarError: {
      heading: 'Упс!',
      content: 'Произошла ошибка загрузки ёлочки',
    },
    giftError: {
      heading: 'Упс!',
      content: 'Произошла ошибка при разбитии шарика',
    },
    rules: {
      heading: [],
      content: 'Прежде чем начать игру, ознакомься с ',
    },
    notHaveKeys: {
      heading: 'У тебя пока нет молотков :(',
      content:
        'Покупай на сумму от 800₽ или товары-спонсоры в магазинах SPAR, Победа и Семья, чтобы получить их',
    },
    allDaysOpened: {
      heading: 'Все игрушки разбиты!',
      content:
        'Переходи к следующей ёлочке, чтобы получить ещё больше подарков!',
      button: 'Перейти',
    },
    gameStartingDate: {
      heading: ['Добро пожаловать!'],
      subheading: ['Готовим подарки!', 'Игра начнётся 14 октября'],
      button: 'Продолжить',
    },
    actionEnd: {
      heading: 'Игра подошла к концу!',
      content: 'Ждите новых розыгрышей',
    },
  },
  keyDescription: {
    heading: 'Это молоток',
    description: 'Чтобы открыть подарок, потяни его к игрушке с номером',
  },
  keysBox: {
    keys: 'Молотки',
  },
  keysCarousel: {
    emptyMessage: 'Совершай покупки и получай молотки',
  },
}

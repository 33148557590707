import { TextTitleSmallerHomeNewYearGame } from './TextTitleSmallerHomeNewYearGame'
import { TextTitleSmallerHomeTreasureIslandGame } from './TextTitleSmallerHomeTreasureIslandGame'
import { TextTitleSmallerHomeKDPayGame } from './TextTitleSmallerHomeKDPayGame'
import { TextTitleSmallerHomeNewYearGame2024_2025 } from './TextTitleSmallerHomeNewYearGame2024_2025'

const TextTitleSmallerHomeByGameId = {
  1: TextTitleSmallerHomeNewYearGame,
  2: TextTitleSmallerHomeTreasureIslandGame,
  3: TextTitleSmallerHomeKDPayGame,
  4: TextTitleSmallerHomeNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleSmallerHome = TextTitleSmallerHomeByGameId[gameId]

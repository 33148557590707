import { Button, Text } from 'ui/atoms'
import { PopupInfo } from 'ui/molecules'
import { buttonProps } from './buttonProps'
import { contentProps } from './contentProps'
import { headingProps } from './headingProps'
import { config } from 'config'

export const PopupActionEnd = ({ isOpened, onClose }) => (
  <PopupInfo isOpened={isOpened} onClose={onClose}>
    {config.texts.popups.actionEnd.heading && (
      <Text {...headingProps}>{config.texts.popups.actionEnd.heading}</Text>
    )}
    {config.texts.popups.actionEnd.content && (
      <Text {...contentProps}>{config.texts.popups.actionEnd.content}</Text>
    )}
    <Button {...buttonProps} onClick={onClose}>
      Продолжить
    </Button>
  </PopupInfo>
)
